/**
 * 活动管理-活动信息-关联项目
 * luxinwen
 * 2023-03-22
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content" v-grant="'OSP_ACTIVITY_ITEM_SAVE'">
        <Button type="primary" @click="addData">添加项目</Button>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="pic">
            <img :src="params.row.buildingInfo.masterImage" class="img-preview" @click="previewImage(params.row.buildingInfo.masterImage)">
          </template>
          <template slot-scope="params" slot="buildingName">
            <span>{{ params.row.buildingInfo.name }}</span>
          </template>
          <template slot-scope="params" slot="cityName">
            <span>{{ (allCityList.find(item => item.regionId === params.row.buildingInfo.cityId) || {}).regionName }}</span>
          </template>
          <template slot-scope="params" slot="category">
            <span>{{ params.row.buildingInfo.categoryValue }}</span>
          </template>
          <template slot-scope="params" slot="saleType">
            <span>{{ params.row.buildingInfo.saleTypeValue }}</span>
          </template>
          <template slot-scope="params" slot="status">
            <Tag :color="params.row.buildingInfo.status === 2 ? 'error' : 'success'">{{ (statusList.find(item => item.code === params.row.buildingInfo.status) || {}).desc }}</Tag>
          </template>
          <template slot-scope="params" slot="operation">
            <Button @click="showData(params.row)" v-grant="'OSP_ACTIVITY_ITEM_VIEW'">查看</Button>
            <Button type="error" @click="removeData(params.row)" v-grant="'OSP_ACTIVITY_ITEM_DELETE'">删除</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <edit-drawer v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag" @success="getData"></edit-drawer>
    <modal-select-project v-model="projectDisplay" :data-id="dataId" :city-id="cityId" multiple @submit="submitSelect"></modal-select-project>
    <modal-preview v-model="previewImageUrl"></modal-preview>
  </div>
</template>

<script>
  import editDrawer from '@/views/project/mgm/edit.vue';

  export default {
    components: {
      editDrawer
    },
    data() {
      return {
        tableColumns: [
          {
            title: '项目ID',
            key: 'buildingId'
          },
          {
            title: '项目主图',
            width: 220,
            slot: 'pic'
          },
          {
            title: '项目名称',
            slot: 'buildingName'
          },
          {
            title: '项目城市',
            slot: 'cityName'
          },
          {
            title: '项目类型',
            slot: 'category'
          },
          {
            title: '项目分类',
            slot: 'saleType'
          },
          {
            title: '项目状态',
            slot: 'status'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'add',    // 编辑抽屉状态
        dataId: '',
        cityId: '',
        allCityList: [],
        statusList: [],
        projectDisplay: false,
        previewImageUrl: ''
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_ACTIVITY_ITEM_VIEW, this.$grant.OSP_ACTIVITY_ITEM_DELETE];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.dataId = this.$route.query.id;
      this.cityId = this.$route.query.cityId;
      if (this.dataId) this.getData();
      this.getCityRegionAllList();
      this.getStatus();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, {
          activityId: Number(this.dataId),
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        this.$axios({
          url: this.$api.activity.queryBuildingList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 获取所有城市列表
       */
      getCityRegionAllList() {
        this.$axios({
          url: this.$api.region.queryCityRegionAllList,
          data: {
            serviceType: 1
          }
        }).then(data => {
          this.allCityList = data || [];
        });
      },
      /**
       * 获取项目状态
       */
      getStatus() {
        this.$axios({
          url: this.$api.project.queryBuildingStatus,
          data: {}
        }).then(data => {
          this.statusList = data || [];
        });
      },
      /**
       * 新增数据
       */
      addData() {
        this.projectDisplay = true;
      },
      /**
       * 查看
       */
      showData(row) {
        this.drawerFlag = 'show';
        this.drawerData = {
          id: row.buildingId
        };
        this.drawerDisplay = true;
      },
      /**
       * 删除数据
       */
      removeData(row) {
        this.$confirm(`确定要删除 [${row.buildingName}] 吗？`).then(() => {
          this.$axios({
            url: this.$api.activity.deleteBuilding,
            data: {
              id: row.id
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: '删除成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 确认选择
       */
      submitSelect(list = []) {
        let relList = [];
        list.forEach(item => {
          relList.push({
            activityId: Number(this.dataId),
            buildingId: item.buildingId
          });
        });
        this.$axios({
          url: this.$api.activity.addBuilding,
          data: {
            relList
          },
          loading: true
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '添加成功'
          });
          this.getData();
        });
      },
      /**
       * 预览
       */
      previewImage(url) {
        this.previewImageUrl = url;
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>